import React, { Component } from "react";
import PageHelmet from "../components/common/Helmet";
import Breadcrumb from "../elements/common/Breadcrumb";
import { FiCast , FiLayers , FiUsers , FiMonitor ,FiChevronUp } from "react-icons/fi";
import ScrollToTop from 'react-scroll-up';
// import Header from "../component/header/Header";
import HeaderFive from "../components/header/HeaderFive";
import FooterTwo from "../components/footer/FooterTwo";
import ServiceExpain from "../components/ServiceExplain";
import ContactForm from "../blocks/ContactForm";
import Portfolio from "../blocks/Portfolio";
import Accordion01 from "../elements/Accordion";
import CallAction from "../components/HomeLayout/homeOne/callaction/CallAction";



const ServiceList = [   
    {
        icon: <FiCast />,
        title: 'SEO',
        description: 'Our team of experts will work with you to develop a customized SEO strategy that will help you rank higher in search engine results pages and attract more qualified traffic to your website.'
    },
    {
        icon: <FiLayers />,
        title: 'PPC',
        description: 'With our top-quality PPC (pay-per-click) services, [Company Name] can help you drive in-organic growth for your business and reach new audiences online.'
    },
    {
        icon: <FiUsers />,
        title: 'SMM',
        description: 'To help businesses of all sizes succeed on social media. Our team of experienced professionals can help you develop a social media strategy that aligns with your business goals and objectives'
    },
    {
        icon: <FiMonitor />,
        title: 'Marketing Automation',
        description: 'We can help you choose and implement a marketing automation platform that meets your needs.'
    },
    {
        icon: <FiCast />,
        title: 'Influencer Marketing',
        description: 'e can help you identify influencers in your industry who have the reach, credibility, and influence to help promote your products or services.'
    },
    {
        icon: <FiMonitor />,
        title: 'Performance Marketing',
        description: 'Performance marketing can be a highly effective way for businesses to reach their target audience and drive measurable results'
    },
]


const first3Items = ServiceList.slice(0,3);


class DigitalMarketing extends Component{
    render(){
        return(
            <React.Fragment>
                <PageHelmet pageTitle='DIGITAL MARKETING AGENCY IN INDIA' />
                {/* <HeaderFive headertransparent="header--transparent"  colorblack="color--black" logoname="logo.png" /> */}

                <HeaderFive headerPosition="header--static logoresize" logo="all-dark" color="color-black"/>


                {/* Start Breadcrump Area */}
                <Breadcrumb title={'DIGITAL MARKETING AGENCY IN INDIA'} bg='breadcrumb-area rn-bg-color ptb--150 bg-4'   />
                {/* End Breadcrump Area */}

                

                {/* Start Service Area */}
                <div className="service-area creative-service-wrapper pt--90 pb--120 bg_color--1">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="section-title text-center mb--30">
                                    <h2>Marketing Strategy</h2>
                                   
                                    <p>Digital marketing is our area of expertise at CODEHUNTER, and we have a staff of qualified experts committed to assisting companies in their online success. We have the knowledge and experience to achieve results whether you need assistance with SEO, social media marketing, email marketing, or paid advertising. We use analytics and insights to enhance our campaigns and produce quantifiable outcomes for our clients. This is a data-driven approach to digital marketing.</p>
                                    <img src="https://www.codehunters.co.in/img/dm2.png"/>
                                </div>
                            </div>
                        </div>
                        <div className="row creative-service">
                        <div className="col-lg-12 pt--100">
                                <div className="section-title text-center mb--30">
                                    <h2>Digital Marketing</h2>
                                   
                                    <p>By utilising our decades of experience in digital marketing services, we enable multinational corporations and businesses to achieve this 360-degree online reputation management.</p>
                                    
                                </div>
                            </div>
                            {ServiceList.map( (val , i) => (
                                <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12" key={i}>
                                    <a className="text-center" href="/service-details">
                                        <div className="service service__style--2">
                                            <div className="icon">
                                                {val.icon}
                                            </div>
                                            <div className="content">
                                                <h3 className="title">{val.title}</h3>
                                                <p>{val.description}</p>
                                            </div>
                                        </div>
                                    </a>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
                {/* End Service Area */}

                <CallAction/>

                               

             


                {/* <ServiceExpain/> */}

                {/* Start About Area  */}
                <div className="rn-about-area ptb--120 bg_color--5">
                    <div className="container">
                        <div className="row row--35 align-items-center">
                            <div className="col-lg-6">
                                <div className="thumbnail">
                                    <img className="w-100" src="https://test.codehunters.co.in/assets/images/about/about-4.png" alt="About Images"/>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="about-inner inner">
                                    <div className="section-title">
                                        <h2 className="title">Working Process</h2>
                                        <p>CODEHUNTERS work for promoting a business or brand online and made your business grow</p>
                                    </div>
                                    <div className="accordion-wrapper mt--30">
                                        <Accordion01 />
                                    </div>
                                    <div className="about-button mt--50">
                                        <a className="rn-button-style--2 btn-solid" href="/about">See how it works</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End About Area  */}

                <div className="service-area creative-service-wrapper pt--90 pb--120 bg_color--1">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="section-title text-center mb--30">
                                    <h2>Consistency is the key to Success</h2>
                                    
                                    <p>We deliver rich experiences on small screens thanks to our extraordinarily in-depth knowledge of user experience and mobile technology, which directly affects your company's success, operational simplicity, and consumer engagement. In order to maximise the influence on your end users, we collaborate with various ecosystems.</p>
                                    <img src="https://test.codehunters.co.in/assets/images/dm2.webp"/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


                <div className="service-area creative-service-wrapper pt--90 pb--120 bg_color--1">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="section-title text-center mb--30">
                                    <h2>We work with</h2>
                                    
                                    <p>We deliver rich experiences on small screens thanks to our extraordinarily in-depth knowledge of user experience and mobile technology, which directly affects your company's success, operational simplicity, and consumer engagement. In order to maximise the influence on your end users, we collaborate with various ecosystems.</p>
                                    <img src="https://test.codehunters.co.in/assets/images/gd1.svg"/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                


                <Portfolio title='Marketing works' description='We grow our clients business exponentially'/>
                
               <ContactForm/>
                

                {/* Start Back To Top */}
                <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp />
                    </ScrollToTop>
                </div>
                {/* End Back To Top */}
                
                <FooterTwo />


            </React.Fragment>
        )
    }
}
export default DigitalMarketing;