import React, { Component } from "react";
import PageHelmet from "../components/common/Helmet";
import Breadcrumb from "../elements/common/Breadcrumb";
import { FiCast , FiLayers , FiUsers , FiMonitor ,FiChevronUp } from "react-icons/fi";
import ScrollToTop from 'react-scroll-up';
// import Header from "../component/header/Header";
import HeaderFive from "../components/header/HeaderFive";
import FooterTwo from "../components/footer/FooterTwo";
import ServiceExpain from "../components/ServiceExplain";
import ContactForm from "../blocks/ContactForm";
import Portfolio from "../blocks/Portfolio";
import CallAction from "../components/HomeLayout/homeOne/callaction/CallAction";



const WebDevelopmentList = [   
    {
        icon: <FiCast />,
        title: 'Business Stratagy',
        description: 'we understand that enterprises have unique needs and challenges when it comes to their online presence. Thats why we offer a range of enterprise solutions to help businesses of all sizes succeed in the digital world'
    },
    {
        icon: <FiLayers />,
        title: 'Website Development',
        description: 'At CODEHUNTERS, we specialize in e-commerce development for businesses of all sizes and sectors. Our team of experienced developers can help you build and manage an online store that is tailored to your specific needs and targets.'
    },
    {
        icon: <FiUsers />,
        title: 'Marketing & Reporting',
        description: 'At CODEHUNTERS, we specialize in building and maintaining the backend systems and APIs that power websites and web-based applications. Our team of experienced developers can help you with any aspects of dependencies'
    },
    
]


const ExplainList = [
    {
        title : 'Enterprises',
       
        description : 'We specialize in creating custom websites for enterprises, taking into account their specific needs and goals. Our team can help you design and build a website that is tailored to your business, and can scale as your needs grow.',
        sub : 'ERP development',
        l1 : 'Supply chain management',
        l2 : 'Financial and accounting management',
        l3 : 'Customer relationship management',
        img : 'https://media.istockphoto.com/id/1218614876/photo/looking-up-a-reflections-on-glass-covered-corporate-building.jpg?s=612x612&w=0&k=20&c=5wtXtsyFMpENLXr-Kkfz5GnCXP2Av_XfFFxDIfFlVNk='

    },
    {
        title : 'E-Commerce',
        description : 'Our e-commerce website development services can help your business succeed in the digital world. We specialize in building and customizing online stores that are tailored to your specific needs and goals. Our team of experienced developers can help you choose the best e-commerce platform, design and customize your store, manage your product catalog, integrate with payment processors and shipping carriers, and provide ongoing support and maintenance.',
        sub : 'E-Commerce development',
        l1 : 'Store design and customization',
        l2 : 'Product management',
        l3 : 'Payment processing',
        img : 'https://d39bmof8blmz8u.cloudfront.net/wp-content/uploads/2022/07/ecommerce-que-es.png'
    },
    {
        title : 'Web Needs',
        description : 'Our team specializes in API development for various cross-platform applications. We have experience working with a wide range of technologies and can help you design and build APIs that allow your software to seamlessly integrate with other systems and platforms. Whether you need to expose data or functionality to external developers, or connect your systems to third-party services, we can help you create an API solution that meets your needs.',
        sub : 'API development',
        l1 : 'Exposing data and functionality',
        l2 : 'Connecting systems',
        l3 : 'Facilitating integrations',
        img : 'https://www.mend.io/wp-content/media/2021/06/Cloud-Security-Blog.png'
    }

]


const PortfolioListContent = [
    {
        image: 'image-1',
        category: 'Development',
        title: 'Getting tickets to the big show'
    },
    {
        image: 'image-2',
        category: 'Development',
        title: 'Getting tickets to the big show'
    },
    {
        image: 'image-3',
        category: 'Development',
        title: 'Getting tickets to the big show'
    },
    {
        image: 'image-4',
        category: 'Development',
        title: 'Getting tickets to the big show'
    },
    {
        image: 'image-1',
        category: 'Digital Screens',
        title: 'PRO-RGB',
        source:'https://pro-rgb.com/'
    },
    {
        image: 'image-4',
        category: 'Development',
        title: 'Getting tickets to the big show'
    }
]


const first3Items = WebDevelopmentList.slice(0,3);


class WebDevelopment extends Component{
    render(){
        return(
            <React.Fragment>
                <PageHelmet pageTitle='WEB DEVELOPMENT COMPANY' />
                {/* <HeaderFive headertransparent="header--transparent"  colorblack="color--black" logoname="logo.png" /> */}

                <HeaderFive headerPosition="header--static logoresize" logo="all-dark" color="color-black"/>


                {/* Start Breadcrump Area */}
                <Breadcrumb title={'WEB DEVELOPMENT COMPANY'} bg='breadcrumb-area rn-bg-color ptb--150 bg-6' headbelow_p={'we offer a full range of web development services to help businesses of all sizes succeed in the digital world. Our team of experienced developers can help you with any kind of web applications'}  />
                {/* End Breadcrump Area */}

                

                {/* Start Service Area */}
                <div className="service-area creative-service-wrapper pt--90 pb--120 bg_color--1">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="section-title text-center mb--30">
                                    <h2>Web development</h2>
                                    <p>we offer a full range of web development services to help businesses<br></br> of all sizes succeed in the digital world. Our team of experienced developers can help you with any kind of web applications<br /></p>
                                </div>
                            </div>
                        </div>
                        <div className="row creative-service">
                            {WebDevelopmentList.map( (val , i) => (
                                <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12" key={i}>
                                    <a className="text-center" href="/service-details">
                                        <div className="service service__style--2">
                                            <div className="icon">
                                                {val.icon}
                                            </div>
                                            <div className="content">
                                                <h3 className="title">{val.title}</h3>
                                                <p>{val.description}</p>
                                            </div>
                                        </div>
                                    </a>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
                {/* End Service Area */}

                               
                {/* <ServiceExpain/> */}
                {ExplainList.map( (val , i) => (
                <div className="about-area ptb--60  bg_color--1" key={i}>
                    <div className="about-wrapper">
                        <div className="container">
                            <div className="row row--35">
                                <div className="col-lg-5">
                                    <div className="thumbnail">
                                        <img className="w-100" src={val.img} alt="About Images"/>
                                    </div>
                                </div>
                                <div className="col-lg-7">
                                    <div className="about-inner inner">
                                        <div className="section-title">
                                            <h2 className="title">{val.title}</h2>
                                            <p className="description">{val.description}</p>
                                            <h4 className="title">{val.sub}</h4>
                                            <ul>
                                                <li>
                                                    {val.l1}
                                                </li>
                                                <li>
                                                    {val.l2}
                                                </li>
                                                <li>
                                                    {val.l3}
                                                </li>
                                            </ul>
                                        </div>
                                        
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                ))}

                {/* Start Call Action Area */}
                <CallAction/>
                {/* End Call Action Area */}

                <Portfolio/>

                
                
               <ContactForm/>
                

                {/* Start Back To Top */}
                <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp />
                    </ScrollToTop>
                </div>
                {/* End Back To Top */}
                
                <FooterTwo />


            </React.Fragment>
        )
    }
}
export default WebDevelopment;