import React, { Component } from "react";
import {Link} from "react-router-dom";

const PortfolioListContentApp = [
    {
        image: 'image-1',
        category: 'Women Safety',
        title: 'OCTUS',
        url:'https://www.youtube.com/watch?v=yzrK254M_u8' 
    },
    {
        image: 'image-2',
        category: 'E-Commerce',
        title: 'JKM-MART',
        url:'https://play.google.com/store/apps/details?id=com.JKMMart.androidapp&hl=en&gl=US' 


    },
    {
        image: 'image-3',
        category: 'Travel',
        title: 'Pick@Call',
        url:'' 

    },
    
]

class PortfolioListTwo extends Component{
    render(){
        const {column , styevariation } = this.props;
       
        
            const list = PortfolioListContentApp.slice(0 , this.props.item);
            
    
        
        return(
            <React.Fragment> 
                
                {list.map((value , index) => (
                    <div className={`${column}`} key={index}>
                        <div className={`portfolio ${styevariation}`}>
                            <div className="thumbnail-inner">
                                <div className={`thumbnail ${value.image}`}></div>
                                <div className={`bg-blr-image ${value.image}`}></div>
                            </div>
                            <div className="content">
                                <div className="inner">
                                    <p>{value.category}</p>
                                    <h4><a href={value.url}>{value.title}</a></h4>
                                    <div className="portfolio-button">
                                        <a className="rn-btn" target='_blank' href={value.url}>View Details</a>
                                    </div>
                                </div>
                            </div>
                            
                        </div>
                    </div>
                ))}
               
            </React.Fragment>
        )
    }
}
export default PortfolioListTwo;