import React, { Component } from "react";

class BrandTwo extends Component{
    render(){
        return(
            <React.Fragment>
                <ul className="brand-style-2">
                    <li>
                        <a href="https://megasolutionsbh.com/" target='_blank'><img src="/assets/images/brand/brand-01.png" alt="Logo Images"/></a>
                    </li>
                    <li>
                        <a href="https://quingpublications.com/" target='_blank'><img src="/assets/images/brand/brand-02.png" alt="Logo Images"/></a>
                    </li>
                    <li>
                        <a href="https://www.wahrheitinternational.com/" target='_blank'><img src="/assets/images/brand/brand-03.png" alt="Logo Images"/></a>
                    </li>
                    <li>
                        <a href="https://mobilecarev2.com/" target='_blank'><img src="/assets/images/brand/brand-04.png" alt="Logo Images"/></a>
                    </li>
                    <li>
                        <a href="https://pro-rgb.com/" target='_blank'><img src="/assets/images/brand/brand-05.png" alt="Logo Images"/></a>
                    </li>
                   
                </ul>
            </React.Fragment>
        )
    }
}
export default BrandTwo;