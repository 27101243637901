import React, { Component } from "react";
import PageHelmet from "../components/common/Helmet";
import Breadcrumb from "../elements/common/Breadcrumb";
import { FiCast , FiLayers , FiUsers , FiMonitor ,FiChevronUp } from "react-icons/fi";
import ScrollToTop from 'react-scroll-up';
// import Header from "../component/header/Header";
import HeaderFive from "../components/header/HeaderFive";
import Footer from "../components/footer/Footer";
import BrandTwo from "../elements/BrandTwo";



const ServiceList = [   
    {
        icon: <FiCast />,
        title: 'Constrained Budget',
        description: 'If you have a well defined project story, and complete scope ready, we can give you a fixed cost and projected timeline.We compose for you the best in class team having'
    },
    {
        icon: <FiLayers />,
        title: 'Evolving Scope',
        description: 'Best suited when you have a blueprint of a large project OR an ongoing project that needs continuous enhancements – where iterations are eminent. We compose for you the best in class team having '
    },
    
]

const first3Items = ServiceList.slice(0,3);


class WhatWeDid extends Component{
    render(){
        let title = 'What we did',
        description = 'There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which dont look even slightly believable. If you are going to use a passage of Lorem Ipsum Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which dont look even slightly believable. If you are going.';
        return(
            <React.Fragment>
                <PageHelmet pageTitle='What we did' />

                <HeaderFive headerPosition="header--static logoresize" logo="all-dark" color="color-black"/>
                
                {/* Start Breadcrump Area */}
                <Breadcrumb title={'What we did'}
                
                bg='breadcrumb-area rn-bg-color ptb--150 bg-14' 
                headbelow_p = {'We started as students and became one of the leading Startups of Tamilnadu'}    />
                {/* End Breadcrump Area */}

                


                {/* Start Service Area */}
                <div className="service-area creative-service-wrapper pt--90 pb--120 bg_color--1">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="section-title text-center mb--30">
                                    <h2>Started from</h2>
                                    <p>Every project is a unique project, and hence – requires very special and dedicated treatment. Besides the critical triangle of cost, time and scope – the working model between the client and the agency also plays a crucial part in balancing the former. 
                                        That’s why, we have a very robust, yet simple models – that are extremely flexible and client-centric inside.</p>
                                </div>
                            </div>
                        </div>
                        <div className="row creative-service">
                            {ServiceList.map( (val , i) => (
                                <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12" key={i}>
                                    <a className="" href="/service-details">
                                        <div className="service service__style--2">
                                            <div className="icon">
                                                {val.icon}
                                            </div>
                                            <div className="content">
                                                <h3 className="title">{val.title}</h3>
                                                <p>{val.description}</p>
                                            </div>
                                        </div>
                                    </a>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
                {/* End Service Area */}

                {/* Start work Area */}
                <div className="rn-column-area rn-section-gap pt--90 pb--120">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="single-column">
                                    <h2 className="tilte text-center">In what we are special</h2>
                                    <p className="text-center">Flexible and Reliable</p>
                                </div>
                                <div className="col-lg-12 col-12 order-1 order-lg-2">
                                    <div className="thumb position-relative">
                                        <img className="w-100" src="https://test.codehunters.co.in/assets/images/bg/SIH.png" alt="Service Images"/>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End work Area */}


                 

               

                {/* Start CounterUp Area */}
                {/* <div className="rn-counterup-area pb--120 bg_color--1">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="section-title text-center">
                                    <h3 className="fontWeight500">Our Fun Facts</h3>
                                </div>
                            </div>
                        </div>
                        <CounterOne />
                    </div>
                </div> */}
                {/* End CounterUp Area */}

                

                

                {/* Start Brand Area */}
                <div className="rn-brand-area brand-separation bg_color--5 ptb--120">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <BrandTwo />
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Brand Area */}

                {/* Start Back To Top */}
                <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp />
                    </ScrollToTop>
                </div>
                {/* End Back To Top */}
                
                <Footer />

            </React.Fragment>
        )
    }
}
export default WhatWeDid;