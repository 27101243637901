import React, { Component } from "react";
import PageHelmet from "../components/common/Helmet";
import Breadcrumb from "../elements/common/Breadcrumb";
import { FiCast , FiLayers , FiUsers , FiMonitor ,FiChevronUp } from "react-icons/fi";
import ScrollToTop from 'react-scroll-up';
// import Header from "../component/header/Header";
import HeaderFive from "../components/header/HeaderFive";
import FooterTwo from "../components/footer/FooterTwo";
import ServiceExpain from "../components/ServiceExplain";
import ContactForm from "../blocks/ContactForm";
import Portfolio from "../blocks/Portfolio";
import CounterOne from "../elements/counters/CounterOne";
import BrandTwo from "../elements/BrandTwo";
import CounterTwo from "../elements/counters/CounterTwo";



const ServiceList = [   
    {
        icon: <FiCast />,
        title: 'Live sessions',
        description: 'You may learn new skills and information more quickly and effectively with the aid of our live course sessions, which provide a distinctive and engaging learning experience.'
    },
    {
        icon: <FiLayers />,
        title: 'Free courses',
        description: 'We provide a variety of free courses in addition to our extensive and industry-relevant premium courses that are meant to give people a strong foundation in a variety of technical sectors. These open courses cover a wide range of subjects, including Linux, web development, software development, and ethical hacking.'
    },
    {
        icon: <FiUsers />,
        title: 'Career guidance',
        description: 'Our organisation not only offers thorough courses that are applicable to industry, but also helpful career advice and mock interviews for students. Our career counselling services are intended to assist students in determining their areas of strength and interest and to assist them in selecting the most suitable career path.'
    },
   
]


let Data = [
    {
        countNum : 54,
        countTitle: 'Projects done so far',
    },
    {
        countNum : 107,
        countTitle: 'Clients increasing day by day',
    },
    {
        countNum : 400,
        countTitle: 'Ads running and still counting',
    },
];





class Education extends Component{
    render(){
        return(
            <React.Fragment>
                <PageHelmet pageTitle='Education EXPLORE' />
                {/* <HeaderFive headertransparent="header--transparent"  colorblack="color--black" logoname="logo.png" /> */}

                <HeaderFive headerPosition="header--static logoresize" logo="all-dark" color="color-black"/>


                {/* Start Breadcrump Area */}
                <Breadcrumb title={'EXPLORE'}  bg='breadcrumb-area rn-bg-color ptb--150 bg-13'  />
                {/* End Breadcrump Area */}

                

                {/* Start Service Area */}

                <div className="service-area creative-service-wrapper pt--90 pb--120 bg_color--1">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="section-title text-center mb--30">
                                    <h2>Youtube</h2>
                                    
                                    <p>
                                    With the assistance of our YouTube channel and playlists, you may learn more about technology and enhance your professional abilities. We cover a wide range of subjects on our channel, including Linux, Metasploit, web development, ethical hacking, and more. Our playlists are intended to provide you in-depth understanding and useful abilities in a range of technological subjects. With the help of our channel, you may sharpen your business 
                                    English while learning the most recent methods and equipment. Join us now to explore the limitless opportunities offered by technology and to improve your job chances.
                                    </p>
                                    <img src="https://dcdh7ea8gkhvt.cloudfront.net/wp-content/uploads/2022/02/mobile-app-development-img.webp"/>
                                    
                                </div>
                                
                            </div>
                            
                        </div>
                        
                    </div>
                    <img src="https://test.codehunters.co.in/assets/images/divider/Dp.png"/>
                </div>
                


                <div className="service-area creative-service-wrapper pt--90 pb--120 bg_color--1">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="section-title text-center mb--30">
                                    <h2>Courses Offered</h2>
                                    <p>Our company offers a wide range of professional courses to help individuals enhance their skills and advance in their careers.</p>
                                </div>
                            </div>
                        </div>
                        <div className="row creative-service">
                            {ServiceList.map( (val , i) => (
                                <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12" key={i}>
                                    <a className="text-center" href="/service-details">
                                        <div className="service service__style--2">
                                            <div className="icon">
                                                {val.icon}
                                            </div>
                                            <div className="content">
                                                <h3 className="title">{val.title}</h3>
                                                <p>{val.description}</p>
                                            </div>
                                        </div>
                                    </a>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
                {/* End Service Area */}


                <div className="service-area creative-service-wrapper pt--90 pb--120 bg_color--3">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="section-title text-center mb--30">
                                    <h2>Playlists</h2>
                                    
                                    <p>
                                    These free courses are open for you.</p>
                                    
                                    
                                </div>
                                
                            </div>
                            
                            
                        </div>
                        <div className="row">
                        
                            {/* <div class="thumb position-relative"><img class="w-100" src="https://test.codehunters.co.in/assets/images/blog/w.jpg" alt="Service Images"/>
                            <button class="video-popup position-top-center"><span class="play-icon"></span></button>
                            </div> */}

                            <div class="col-lg-6 col-md-6 col-sm-6 col-12">
                            <div class="blog blog-style--1">
                                <div class="thumbnail">
                                    <a href="blog-details.html">
                                        <img class="w-100" src="https://test.codehunters.co.in/assets/images/blog/m.jpg" alt="Blog Images"/>
                                    </a>
                                </div>
                                <div class="content">
                                    <p class="blogtype">Technology</p>
                                    <h4 class="title"><a href="blog-details.html">Metasploit</a>
                                    </h4>
                                    <div class="blog-btn">
                                        <a class="rn-btn text-white" target='_blank' href="https://www.youtube.com/watch?v=rU3EpM18YJM&list=PL_V9BaWsvjY3TgnofUNyx5F1WEHxl5Qwq">Play Video</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        
                        <div class="col-lg-6 col-md-6 col-sm-6 col-12">
                            <div class="blog blog-style--1">
                                <div class="thumbnail">
                                    <a href="blog-details.html">
                                        <img class="w-100" src="https://test.codehunters.co.in/assets/images/blog/eh.jpg" alt="Blog Images"/>
                                    </a>
                                </div>
                                <div class="content">
                                    <p class="blogtype">Technology</p>
                                    <h4 class="title"><a href="blog-details.html">Ethical Hacking</a>
                                    </h4>
                                    <div class="blog-btn">
                                        <a class="rn-btn text-white" target='_blank' href="https://www.youtube.com/watch?v=JpJsqz4hX_A&list=PL_V9BaWsvjY2dQHH-aJelsENyJXaSkq95">Play Video</a>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-6 col-md-6 col-sm-6 col-12">
                            <div class="blog blog-style--1">
                                <div class="thumbnail">
                                    <a href="blog-details.html">
                                        <img class="w-100" src="https://test.codehunters.co.in/assets/images/blog/l.jpg" alt="Blog Images"/>
                                    </a>
                                </div>
                                <div class="content">
                                    <p class="blogtype">Technology</p>
                                    <h4 class="title"><a href="blog-details.html">Red Hat Linux</a>
                                    </h4>
                                    <div class="blog-btn">
                                        <a class="rn-btn text-white" target='_blank' href="https://www.youtube.com/watch?v=yYzd6uAg-AQ&list=PL_V9BaWsvjY0D_poNcoR7gaS2Aimav2F4">Play Video</a>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-6 col-md-6 col-sm-6 col-12">
                            <div class="blog blog-style--1">
                                <div class="thumbnail">
                                    <a href="blog-details.html">
                                        <img class="w-100" src="https://test.codehunters.co.in/assets/images/blog/n.jpg" alt="Blog Images"/>
                                    </a>
                                </div>
                                <div class="content">
                                    <p class="blogtype">Technology</p>
                                    <h4 class="title"><a href="blog-details.html">Networking</a>
                                    </h4>
                                    <div class="blog-btn">
                                        <a class="rn-btn text-white" target='_blank' href="https://www.youtube.com/watch?v=HB2c9son6Ig&list=PL_V9BaWsvjY0jrBxTjNAT-l1IHmFE-3NC">Play Video</a>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-6 col-md-6 col-sm-6 col-12">
                            <div class="blog blog-style--1">
                                <div class="thumbnail">
                                    <a href="blog-details.html">
                                        <img class="w-100" src="https://test.codehunters.co.in/assets/images/blog/js.jpg" alt="Blog Images"/>
                                    </a>
                                </div>
                                <div class="content">
                                    <p class="blogtype">Technology</p>
                                    <h4 class="title"><a href="blog-details.html">Javascript</a>
                                    </h4>
                                    <div class="blog-btn">
                                        <a class="rn-btn text-white" target='_blank' href="https://www.youtube.com/watch?v=6NVbzLLtUFY&list=PL_V9BaWsvjY1IJTl-oPmSaafIvBCURLUz">Play Video</a>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-6 col-md-6 col-sm-6 col-12">
                            <div class="blog blog-style--1">
                                <div class="thumbnail">
                                    <a href="blog-details.html">
                                        <img class="w-100" src="https://test.codehunters.co.in/assets/images/blog/tt.jpg" alt="Blog Images"/>
                                    </a>
                                </div>
                                <div class="content">
                                    <p class="blogtype">Future Tech</p>
                                    <h4 class="title"><a href="blog-details.html">Tech today with Siva</a>
                                    </h4>
                                    <div class="blog-btn">
                                        <a class="rn-btn text-white" target='_blank' href="https://www.youtube.com/watch?v=2jpjshBhRwo&list=PL_V9BaWsvjY3PhIEspfp98ii5s2770HhU">Play Video</a>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-6 col-md-6 col-sm-6 col-12">
                            <div class="blog blog-style--1">
                                <div class="thumbnail">
                                    <a href="blog-details.html">
                                        <img class="w-100" src="https://test.codehunters.co.in/assets/images/blog/h.jpg" alt="Blog Images"/>
                                    </a>
                                </div>
                                <div class="content">
                                    <p class="blogtype">Future Tech</p>
                                    <h4 class="title"><a href="blog-details.html">Hackthons and Competetions</a>
                                    </h4>
                                    <div class="blog-btn">
                                        <a class="rn-btn text-white" target='_blank' href="https://www.youtube.com/watch?v=BNLSzIa6AEU&list=PL_V9BaWsvjY31A1yYKuy_d6hRWNzCo7fu">Play Video</a>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-6 col-md-6 col-sm-6 col-12">
                            <div class="blog blog-style--1">
                                <div class="thumbnail">
                                    <a href="blog-details.html">
                                        <img class="w-100" src="https://test.codehunters.co.in/assets/images/blog/i.jpg" alt="Blog Images"/>
                                    </a>
                                </div>
                                <div class="content">
                                    <p class="blogtype">Future Tech</p>
                                    <h4 class="title"><a href="blog-details.html">Future Innovations</a>
                                    </h4>
                                    <div class="blog-btn">
                                        <a class="rn-btn text-white" target='_blank' href="https://www.youtube.com/watch?v=LdaiM0X7xBI&list=PL_V9BaWsvjY2aAJs3nLWuCbsGvf1b-o47">Play Video</a>
                                    </div>
                                </div>
                            </div>
                        </div>

                        
                        </div>
                        
                        
                    </div>
                </div>

                <div className="service-area creative-service-wrapper pt--60 pb--60 bg_color--1">
                <h3 className="fontWeight500 text-center">Explored numbers</h3>
                    <div className="container">
                    <CounterTwo/>

                    </div>
                    
                    </div>



                    <div className="service-area creative-service-wrapper pt--90 pb--60 bg_color--1">
                    <div className="container">
                    <BrandTwo/>

                    </div>
                    </div>

                

                

                               
              

              
                
               <ContactForm/>
                

                {/* Start Back To Top */}
                <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp />
                    </ScrollToTop>
                </div>
                {/* End Back To Top */}
                
                <FooterTwo />


            </React.Fragment>
        )
    }
}
export default Education;