import React, { Component } from "react";
import { FiCast , FiLayers , FiUsers , FiMonitor, FiCamera, FiActivity } from "react-icons/fi";

const ServiceList = [
    {
        icon: <FiCast />,
        title: 'Website development',
        description: 'We offer a range of web design and development services, including custom website design, e-commerce development, and responsive design.'
    },
    {
        icon: <FiLayers />,
        title: 'Mobile app development',
        description: 'we specialize in custom app development for a wide range of platforms, including iOS, Android, and the web. Our team of experienced developers can help you turn your app idea into a reality, from initial concept to final launch.'
    },
    {
        icon: <FiUsers />,
        title: 'Marketing & Reporting',
        description: 'we are a full-service digital marketing agency, dedicated to helping businesses of all sizes achieve their online marketing goals.'
    },
    {
        icon: <FiMonitor/>,
        title: 'Software Development',
        description: 'we specialize in custom software development for businesses of all sizes. Our team of experienced developers can help you design, build, and maintain software applications to meet your specific needs. '
    },
    {
        icon: <FiCamera/>,
        title: 'Graphics design',
        description: 'we offer a full range of graphic design services to help businesses of all sizes effectively communicate their brand and message. Our team of experienced designers can help you with a wide range of projects.'
    },
    {
        icon: <FiActivity />,
        title: 'DevOps development',
        description: 'we specialize in DevOps development services to help businesses of all sizes streamline their software development and delivery processes. Our team of experienced developers and IT professionals can help you.'
    }
]

class ServiceThree extends Component{
    render(){
        let title = 'Services we offer';
        return(
            <React.Fragment>
                 <div className="service-area ptb--120 bg_color--5">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="section-title text-center mb--30">
                                    <h2>{title}</h2>
                                    <p>At CODEHUNTERS, we offer a full range of IT services to help businesses of all<br/> sizes succeed in the digital world.</p>
                                </div>
                            </div>
                        </div>
                        <div className="row service-one-wrapper">
                            {ServiceList.map( (val , i) => (
                                <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12" key={i}>
                                    <a href="/service-details">
                                        <div className="service service__style--2">
                                            <div className="icon">
                                                {val.icon}
                                            </div>
                                            <div className="content">
                                                <h3 className="title">{val.title}</h3>
                                                <p>{val.description}</p>
                                            </div>
                                        </div>
                                    </a>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}


export default ServiceThree;