import React, { Component } from "react";
import PageHelmet from "../components/common/Helmet";
import Breadcrumb from "../elements/common/Breadcrumb";
import { FiCast , FiLayers , FiUsers , FiMonitor ,FiChevronUp } from "react-icons/fi";
import ScrollToTop from 'react-scroll-up';
// import Header from "../component/header/Header";
import HeaderFive from "../components/header/HeaderFive";
import Footer from "../components/footer/Footer";
import BrandTwo from "../elements/BrandTwo";



const ServiceList = [   
    {
        icon: <FiCast />,
        title: 'Evolving Scope',
        description: 'Very clear scope and stable set of requirements that isn’t likely to change throughout the project.Can define clear long term milestones – irrespective of the working models chosen (i.e. Agile or Waterfall)'
    },
    {
        icon: <FiLayers />,
        title: 'Constrained Budget',
        description: 'The Scope is defined very briefly and the requirements are very likely to change throughout the project. Can define clear short term milestones – depending on the working model chosen (i.e. Agile or Waterfall)'
    },
    
]

const first3Items = ServiceList.slice(0,3);


class HowWeWork extends Component{
    render(){
        
        return(
            <React.Fragment>
                <PageHelmet pageTitle='How we work' />

                <HeaderFive headerPosition="header--static logoresize" logo="all-dark" color="color-black"/>
                
                {/* Start Breadcrump Area */}
                <Breadcrumb title={'Simple Process'} 

bg='breadcrumb-area rn-bg-color ptb--150 bg-19' 
                
                headbelow_p = {'Are you looking to boost the efficiency of your business process management model? Our experts will deliver just what you need - a full-fledged automation software that will cut down manual errors and increase overall productivity.'} />
                {/* End Breadcrump Area */}


                


                {/* Start Service Area */}
                <div className="service-area creative-service-wrapper pt--90 pb--120 bg_color--1">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="section-title text-center mb--30">
                                    <h2>How to Engage?</h2>
                                    <p>Every project is a unique project, and hence – requires very special and dedicated treatment. Besides the critical triangle of cost, time and scope – the working model between the client and the agency also plays
                                         a crucial part in balancing the former. That’s why, we have a very robust, yet simple models – 
                                         that are extremely flexible and client-centric inside.</p>
                                </div>
                            </div>
                        </div>
                        <div className="row creative-service">
                            {ServiceList.map( (val , i) => (
                                <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12" key={i}>
                                    <a className="" href="/service-details">
                                        <div className="service service__style--2">
                                            <div className="icon">
                                                {val.icon}
                                            </div>
                                            <div className="content">
                                                <h3 className="title">{val.title}</h3>
                                                <p>{val.description}</p>
                                            </div>
                                        </div>
                                    </a>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
                {/* End Service Area */}

                {/* Start work Area */}
                <div className="rn-column-area rn-section-gap pt--90 pb--120">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="single-column">
                                    <h2 className="tilte text-center">Our Workflow</h2>
                                    <p className="text-center">Flexible and Reliable</p>
                                </div>
                                <div className="col-lg-12 col-12 order-1 order-lg-2">
                                    <div className="thumb position-relative">
                                        <img className="w-100" src="https://test.codehunters.co.in/assets/images/ab.png" alt="Service Images"/>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End work Area */}


                 

               

                {/* Start CounterUp Area */}
                {/* <div className="rn-counterup-area pb--120 bg_color--1">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="section-title text-center">
                                    <h3 className="fontWeight500">Our Fun Facts</h3>
                                </div>
                            </div>
                        </div>
                        <CounterOne />
                    </div>
                </div> */}
                {/* End CounterUp Area */}

                

                

                {/* Start Brand Area */}
                <div className="rn-brand-area brand-separation bg_color--5 ptb--120">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <BrandTwo />
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Brand Area */}

                {/* Start Back To Top */}
                <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp />
                    </ScrollToTop>
                </div>
                {/* End Back To Top */}
                
                <Footer />

            </React.Fragment>
        )
    }
}
export default HowWeWork;