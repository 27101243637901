import React, { Component , Fragment } from "react";
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from "react-icons/fi";
import Footer from "../components/footer/Footer";

import SliderOne from "../components/slider/SliderOne";
import CounterOne from "../elements/counters/CounterOne";
import Testimonial from "../elements/Testimonial";
import About from "../components/HomeLayout/homeOne/About";
import Portfolio from "../components/HomeLayout/homeOne/Portfolio";
import BlogContent from "../elements/blog/BlogContent";
import BrandTwo from "../elements/BrandTwo";
import Helmet from "../components/common/Helmet";
import ServiceThree from "../elements/service/ServiceThree";
import CallAction from "../components/HomeLayout/homeOne/callaction/CallAction";
import HeaderFive from "../components/header/HeaderFive";


class Home extends Component{
    render(){
        const PostList = BlogContent.slice(0 , 3);
        return(
            <Fragment> 
                <Helmet pageTitle="CodeHunters" />

                <HeaderFive headerPosition="header--static logoresize" logo="all-dark" color="color-black"/>


                {/* Start Slider Area   */}
                <div className="slider-wrapper">
                    <SliderOne />
                </div>
                {/* End Slider Area   */}
                
                {/* Start About Area */}
                <div className="about-area about-position-top pb--120">
                    <About />
                </div>
                {/* End About Area */}

                <div className="service-area ptb--0  bg_image bg_image--3">
                  
                        <ServiceThree />
                
                </div>

                {/* Start Call Action Area */}
                <div className="portfolio-area ptb--0 ">
                <CallAction/>
                </div>
             
                {/* End Call Action Area */}

                {/* Start Portfolio Area */}
                <div className="portfolio-area ptb--120 bg_color--1">
                    <div className="portfolio-sacousel-inner mb--55">
                        <Portfolio />
                    </div>
                </div>
                {/* End Portfolio Area */}


                {/* Start CounterUp Area */}
                <div className="rn-counterup-area pt--25 pb--110 bg_color--1">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12 mt--30">
                                <div className="section-title text-center">
                                    <h3 className="fontWeight500">Unbelievable Numbers</h3>
                                </div>
                            </div>
                        </div>
                        <CounterOne />
                    </div>
                </div>
                {/* End CounterUp Area */}

                {/* Start Testimonial Area */}
                <div className="rn-testimonial-area ptb--40">
                    <div className="container">
                    <div className="section-title text-center mb--20 mb_sm--0 mb_md--0">
                                    <h2 className="fontWeight900">OUR TESTIMONIALS</h2>
                        </div>
                        <Testimonial />
                    </div>
                </div>
                {/* End Testimonial Area */}


                {/* Start Brand Area */}
                <div className="rn-brand-area brand-separation bg_color--5 pb--120">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12 ">
                                <BrandTwo />
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Brand Area */}

                {/* Start Back To Top */}
                <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp />
                    </ScrollToTop>
                </div>
                {/* End Back To Top */}
                
                <Footer />
            </Fragment>
        )
    }
}
export default Home;