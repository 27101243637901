import axios from 'axios'
import React, {useState} from 'react'
import swal from 'sweetalert';
import { useHistory } from "react-router-dom";




function ContactThree() {
   
    // const [values, setValues]= useState({
    //     Name: '',
    //     Email: '',
    //     Phone: '',
    //     Message:''

    // })
    // const handleChange = (event) => {
    //     setValues({...values,[event.target.name]:[event.target.value]})
    // }
    // const handleSubmit = (event) =>{
        
    //     event.preventDefault();
        
    //     axios.post('http://localhost:8080/insert',values)
        
    //     .then(res => (
    //         swal("Message sent!", "We will connect you ASAP")
    //         )

    //     )
        
    //     .catch(err => console.log(err));
        

    // }


    const navigate = useHistory();

    const [inputs, setInputs] = useState([]);

    const handleChange = (event) => {
        const name = event.target.name;
        const value = event.target.value;
        setInputs(values => ({...values, [name]: value}));
    }
    const handleSubmit = (event) => {
        event.preventDefault();

        // axios.post('http://localhost:80/api/user/save', inputs).then(function(response){
        axios.post('https://www.codehunters.co.in/API/off/', inputs).then(function(response){

            console.log(response.data);
            swal("Message sent!", "We will connect you ASAP");
            swal("We got you!", "We will connect you ASAP","success");
        });
        
    }




  return (

    
    <div>

        <div className="contact-form--1">
                 <div className="container">
                     <div className="row row--35 align-items-start">
                         <div className="col-lg-6 order-2 order-lg-1">
                             <div className="section-title text-left mb--50">
                                 <h2 className="title">Contact us</h2>
                                 <p className="description">Connect with Us via phone: <a href="tel:+919095241578">9095241578</a><br></br> or email:
                                     <a href="mailto:support@codehunters.co.in"> support@codehunters.co.in</a> </p>
                             </div>
                             <div className="form-wrapper">
                                 <form onSubmit={handleSubmit}>
                                     <label htmlFor="item01">
                                         <input
                                            type="text"
                                            name="name"
                                            id="item01"
                                            onChange={handleChange}
                                            placeholder="Your Name "
                                            required
                                        />
                                    </label>

                                    <label htmlFor="item02">
                                        <input
                                            type="text"
                                            name="email"
                                            id="item02"
                                            onChange={handleChange}
                                            placeholder="Your email "
                                            required
                                        />
                                    </label>

                                    <label htmlFor="item03">
                                        <input
                                            type="number"
                                            name="mobile"
                                            id="item03"
                                            onChange={handleChange}
                                            placeholder="Your phone "
                                            required
                                        />
                                    </label>
                                    <label htmlFor="item04">
                                        <textarea
                                            type="text"
                                            id="item04"
                                            name="message"
                                            onChange={handleChange}
                                            placeholder="Your Message"
                                            required
                                        />
                                    </label>
                                    <button className="rn-button-style--2 btn-solid" type="submit" id="mc-embedded-subscribe">Send</button>
                                    

                                    

                                    
                                </form>
                            </div>
                        </div>
                        <div className="col-lg-6 order-1 order-lg-2">
                            <div className="thumbnail mb_md--30 mb_sm--30">
                                <img src='https://test.codehunters.co.in/assets/images/about/call.jpg' alt="trydo"/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>



                            {/* <div className="form-wrapper">
                                <form onSubmit={handleSubmit}>
                                    <label htmlFor="item01">
                                        <input
                                            type="text"
                                            name="Name"
                                            id="item01"
                                            
                                            onChange={handleChange}
                                            placeholder="Your Name *"
                                        />
                                    </label>

                                    <label htmlFor="item02">
                                        <input
                                            type="text"
                                            name="Email"
                                            id="item02"
                                            
                                            onChange={handleChange}
                                            placeholder="Your email *"
                                        />
                                    </label>

                                    <label htmlFor="item03">
                                        <input
                                            type="number"
                                            name="Phone"
                                            id="item03"
                                            
                                            onChange={handleChange}
                                            placeholder="Your phone *"
                                        />
                                    </label>
                                    <label htmlFor="item04">
                                        <textarea
                                            type="text"
                                            id="item04"
                                            name="Message"
                                           
                                            onChange={handleChange}
                                            placeholder="Your Message"
                                        />
                                    </label>
                                    <button type="submit" >Submit</button>
                                </form>
                            </div> */}
                            
    </div>

    
  )
}

export default ContactThree